import { newTracker, trackSelfDescribingEvent } from '@snowplow/browser-tracker';
import { getCookie } from './cookie';

const snowplowHost = window.__ES_SNOWPLOW_HOST;
const snowplowPostPath = window.__ES_SNOWPLOW_POST_PATH;
const snowplowParams = new URLSearchParams(location.search);

export const initSnowplowTracker = () => {
    newTracker('sp', snowplowHost, {
        appId: 'keycloak',
        postPath: snowplowPostPath,
        discoverRootDomain: true,
        cookieSameSite: 'Lax', // Recommended
        contexts: {
            webPage: true,
            session: true,
            gaCookies: true,
        },
    });
};

export const trackSnowplowEvent = (name, event) => {
    try {
        trackSelfDescribingEvent(event);
    } catch (error) {
        console.error('Could not track Snowplow event!', event, error);
        if (window.Sentry) {
            Sentry.withScope(() => {
                Sentry.captureException(new Error('Could not track Snowplow event. ', error));
            });
        }
    }
};

export function getOriginParameters() {
    const allowedSnowplowPageViewFields = [
        'referrer',
        'gclid',
        'cjevent',
        'dclid',
        'msclkid',
        'fbclid',
        'client_id',
        'utm_campaign',
        'utm_source',
        'utm_medium',
        'utm_content',
        'utm_term',
    ];
    if (snowplowParams.has('origin_parameters')) {
        // Python produces url safe base64 encoded strings
        // per https://stackoverflow.com/questions/28100601/decode-url-safe-base64-in-javascript-browser-side
        // to url decode in JS we do this replacement aka '_' => '/' and '-' => '+'
        try {
            const urlDecoded = snowplowParams.get('origin_parameters').replace(/_/g, '/').replace(/-/g, '+');
            const jsonParams = JSON.parse(atob(urlDecoded));
            const stringFields = ['referrer', 'client_id'];
            return Object.keys(jsonParams)
                .filter((param) => allowedSnowplowPageViewFields.includes(param))
                .reduce((pageviewData, field) => {
                    if (stringFields.includes(field)) {
                        pageviewData[field] = jsonParams[field];
                    } else if (typeof jsonParams[field] === 'string') {
                        pageviewData[field] = jsonParams[field].split(',');
                    }
                    return pageviewData;
                }, {});
        } catch (error) {
            if (error instanceof SyntaxError) {
                console.error('SyntaxError decoding origin_parameters for snowplow.', error);
                // eslint-disable-next-line no-undef
            } else if (error instanceof InvalidCharacterError) {
                console.error('InvalidCharacterError decoding origin_parameters for snowplow.', error);
            } else {
                console.error('Error getting/decoding origin_parameters for snowplow.', error);
            }

            if (window.Sentry) {
                Sentry.withScope(() => {
                    Sentry.captureException(
                        new Error('Error getting/decoding origin_parameters for Snowplow.', error),
                    );
                });
            }
        }
    }
    return {};
}

export function getSnowplowPageViewData() {
    const parameters = getOriginParameters();
    const fbp = getCookie('_fbp');
    if (fbp) {
        parameters['fbp'] = fbp;
    }
    return {
        ...parameters,
        landing_page: window.location.pathname,
    };
}
