import { getCookie } from './cookie';
import { getSessionId } from './session';
import optimizely, { enums } from '@optimizely/optimizely-sdk';
import { trackFlagDecision } from './tracking';

// Copy from useFlagDecision in homepage
function setForcedExperimentDecision(userContext, flagKey, variationKey) {
    try {
        const featureKeyMap = userContext.optimizely.projectConfigManager.configObj.featureKeyMap;
        const experimentIdMap = userContext.optimizely.projectConfigManager.configObj.experimentIdMap;
        const flag = featureKeyMap[flagKey];

        if (flag) {
            const experimentId = flag.experimentIds[0];
            const ruleKey = experimentIdMap[experimentId].key;
            if (ruleKey) {
                userContext.setForcedDecision({ flagKey, ruleKey }, { variationKey });
            }
        }
    } catch {
        console.log('Failed to force experiment variant through cookie');
    }
}

// this is currently unused but left in here for future use of experiment/optimizely
// eslint-disable-next-line no-unused-vars
function getAnonExperimentDecision(flagKey) {
    const sessionId = getSessionId();
    const userContext = window.optimizelyClientInstance.createUserContext(sessionId);
    const forceVariationKey = getCookie('experiment-' + flagKey);

    if (forceVariationKey) {
        setForcedExperimentDecision(userContext, flagKey, forceVariationKey);
    }

    return userContext.decide(flagKey);
}

// eslint-disable-next-line no-unused-vars
const onDecision = ({ type, userId, attributes, decisionInfo }) => {
    if (type === 'flag') {
        trackFlagDecision(
            decisionInfo['flagKey'],
            decisionInfo['variationKey'],
            decisionInfo['ruleKey'],
            decisionInfo['enabled'],
        );
    }
};

export const initOptimizely = () => {
    if (!window.optimizelyDatafile) {
        return;
    }
    window.optimizelyClientInstance = optimizely.createInstance({
        datafile: window.optimizelyDatafile,
    });
    window.optimizelyClientInstance.notificationCenter.addNotificationListener(
        enums.NOTIFICATION_TYPES.DECISION,
        onDecision,
    );
};
