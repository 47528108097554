export const checkLocale = () => {
    const locales = window.__ES_LOCALES;
    const params = new URLSearchParams(location.search);
    if (params.has('ui_locales')) {
        const locale = params.get('ui_locales');
        if (locales.split(',').includes(locale)) {
            document.cookie = 'KEYCLOAK_LOCALE=' + locale;
        }
    }
};
