import { initSnowplowTracker } from './snowplow';
import { initEventListener } from './tracking';
import { initOptimizely } from './optimizely';
import { checkLocale } from './locale';
import { initEventHandlers } from './login';
import { initRecaptcha } from './recaptcha';
import { initRemix } from './remix';

initSnowplowTracker();
initEventListener();
initOptimizely();
checkLocale();
initEventHandlers();
initRemix();
initRecaptcha();
