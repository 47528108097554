function toggleShowPassword() {
    const showIconId = 'show-password-icon';
    const hideIconId = 'hide-password-icon';

    let id = this.dataset['passwordId'] ?? 'password';

    const passwordElement = document.getElementById(id);
    if (passwordElement) {
        const type = passwordElement.getAttribute('type') === 'password' ? 'text' : 'password';
        passwordElement.setAttribute('type', type);
        const showPasswordIconElement = document.getElementById(showIconId);
        const hidePasswordIconElement = document.getElementById(hideIconId);
        if (type === 'text') {
            hidePasswordIconElement.style.display = 'inline';
            showPasswordIconElement.style.display = 'none';
        } else {
            showPasswordIconElement.style.display = 'inline';
            hidePasswordIconElement.style.display = 'none';
        }
    } else {
        if (window.Sentry) {
            Sentry.withScope(() => {
                Sentry.captureMessage(
                    'Password input element not found. Could not toggle password visibility.',
                );
            });
        }
    }
}
// todo: refactor this two functions to one and change the event handler in the initEventHandlers function
function handleRegistrationPasswordChanged() {
    updatePasswordState('password', 'password-confirm');
}

function handleUpdatePasswordChanged() {
    updatePasswordState('password-new', 'password-confirm');
}

function updatePasswordState(passwordFieldName, confirmPasswordFieldName) {
    const typedPassword = document.getElementById(passwordFieldName).value;
    const passwordConfirmationField = document.getElementById(confirmPasswordFieldName);
    const passwordRequirementItems = document.getElementsByClassName('password-requirement-list-item');

    const validationResult = verifyPasswordRequirement(typedPassword);

    updatePasswordRequirementItemState(passwordRequirementItems[0], validationResult.minLengthFulfilled);

    updatePasswordRequirementItemState(passwordRequirementItems[1], validationResult.containsDigitFulfilled);

    updatePasswordRequirementItemState(
        passwordRequirementItems[2],
        validationResult.containsSpecialCharacterFulfilled,
    );

    passwordConfirmationField.value = typedPassword;
}

function verifyPasswordRequirement(password) {
    //const containsLetter = /[A-Za-z]/g;
    const containsDigit = /\d/g;
    const containsSpecialCharacter = /[^A-Za-z0-9]/g;

    const minLengthFulfilled = password.length >= 8;
    const containsDigitFulfilled = containsDigit.test(password);
    const containsSpecialCharacterFulfilled = containsSpecialCharacter.test(password);
    const isValid = minLengthFulfilled && containsDigitFulfilled && containsSpecialCharacterFulfilled;

    const result = {
        minLengthFulfilled,
        containsDigitFulfilled,
        containsSpecialCharacterFulfilled,
        isValid,
    };

    return result;
}

function updatePasswordRequirementItemState(listItem, isFulfilled) {
    const fulfilledClass = 'fulfilled';
    if (isFulfilled) {
        listItem.classList.add(fulfilledClass);
    } else {
        listItem.classList.remove(fulfilledClass);
    }
}

function updateSelectedLanguage(selectObject) {
    window.location = selectObject?.target?.value;
}

export function initEventHandlers() {
    document.getElementById('select_locale')?.addEventListener('change', updateSelectedLanguage, false);
    document
        .getElementById('password-visibility-toggle')
        ?.addEventListener('click', toggleShowPassword, false);

    // checks for password field in registration form
    const inputPassword = document.getElementById('password');
    if (inputPassword?.dataset['inputChange'] === 'true') {
        inputPassword.addEventListener('input', handleRegistrationPasswordChanged, false);
    }

    // checks for password field in update form
    const inputPasswordNew = document.getElementById('password-new');
    if (inputPasswordNew?.dataset['inputChange'] === 'true') {
        inputPasswordNew.addEventListener('input', handleUpdatePasswordChanged, false);
    }
}
