function tryGetForm() {
    const registerFormId = 'kc-register-form';
    const loginFormId = 'kc-form-login';
    const resetPasswordFormId = 'kc-reset-password-form';

    const form_by_class = document.getElementsByClassName('kc-form')[0];
    if (form_by_class) {
        return form_by_class;
    }

    const form =
        document.getElementById(loginFormId) ||
        document.getElementById(registerFormId) ||
        document.getElementById(resetPasswordFormId);
    return form;
}

function updateTokenForm(token) {
    const tokenInput = document.getElementById('recaptchaToken');
    if (tokenInput) {
        tokenInput.value = token;
    }
}

function getElementValue(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
        return element.value;
    }
    return null;
}

function sendSentryError(message) {
    if (window.Sentry) {
        Sentry.withScope(() => {
            // todo: do we need some additional data here?
            Sentry.captureException(new Error('Recaptcha error. ' + message));
        });
    }
}

function onSubmitRecaptcha(e, form, recaptchaKey, action) {
    e.preventDefault();

    if (window.grecaptcha) {
        window.grecaptcha.enterprise.ready(() => {
            window.grecaptcha.enterprise
                .execute(recaptchaKey, {
                    action: action,
                })
                .then((token) => {
                    updateTokenForm(token);
                    form.submit();
                    return true;
                });
        });
        return false;
    } else {
        sendSentryError('window.grecaptcha is undefined.');

        // If we cannot load recaptcha enterprise, we want still want to try to submit with null token
        form.submit();
        return true;
    }
}

export const initRecaptcha = () => {
    window.getElementValue = getElementValue;
    const recaptchaKey = getElementValue('recaptchaKey');
    const form = tryGetForm();
    if (recaptchaKey && form) {
        const action = getElementValue('action');
        if (action === null) {
            sendSentryError('Action was null or undefined.');
        }

        form.addEventListener('submit', (e) => onSubmitRecaptcha(e, form, recaptchaKey, action));
    }
};
