function setRemixCss() {
    const BASE_STYLE_SHEET_PATH = 'css/epidemic-base.css';
    const REMIX_BASE_STYLE_SHEET_PATH = 'css/epidemic-remix-base.css';

    const baseStyleSheetIndex = Object.values(document.styleSheets).findIndex(
        (styleSheet) => styleSheet.href && styleSheet.href.endsWith(BASE_STYLE_SHEET_PATH),
    );
    const resourcePath = document.styleSheets[baseStyleSheetIndex].href.split(BASE_STYLE_SHEET_PATH)[0];

    // remove base style sheet
    document.styleSheets[baseStyleSheetIndex].disabled = true;

    // add remix base style sheet
    const linkRef = document.createElement('link');
    linkRef.rel = 'stylesheet';
    linkRef.href = resourcePath + REMIX_BASE_STYLE_SHEET_PATH;
    linkRef.type = 'text/css';
    document.head.appendChild(linkRef);
}

function setRemixRegistrationLayoutImages() {
    const replacementLookup = [
        { artistImage: 'KingSis', replacement: 'login' },
        { artistImage: 'Ooyy', replacement: 'create' },
    ];

    replacementLookup.forEach((lookup) => {
        const pictureElement = document.getElementById('artistImage-' + lookup.artistImage);
        if (pictureElement) {
            Array.from(pictureElement.getElementsByTagName('source')).forEach((source) => {
                const newSrcset = source.srcset.replace(lookup.artistImage, lookup.replacement);
                source.setAttribute('srcset', newSrcset);
            });
            const imgElement = pictureElement.getElementsByTagName('img')[0];
            const newSrc = imgElement.src.replace(lookup.artistImage, lookup.replacement);
            imgElement.setAttribute('src', newSrc);
        }
    });
}

function setRemixIconImages() {
    const replacementLookup = [
        { imgId: 'show-password-icon', fileName: 'eye.svg', remixFileName: 'eye-primary-icon-color.svg' },
        {
            imgId: 'hide-password-icon',
            fileName: 'hidden-eye.svg',
            remixFileName: 'hidden-eye-secondary-icon-color.svg',
        },
        { imgId: 'logo', fileName: 'logo.svg', remixFileName: 'logo-remix.svg' },
    ];

    replacementLookup.forEach((lookup) => {
        const imgElement = document.getElementById(lookup.imgId);
        if (imgElement) {
            const newSrc = imgElement.src.replace(lookup.fileName, lookup.remixFileName);
            imgElement.setAttribute('src', newSrc);
        }
    });
}

function setRemixStyleClasses() {
    const imgElements = document.getElementsByClassName('logo');
    if (imgElements && imgElements.length > 0) {
        for (let imgElement of imgElements) {
            imgElement.classList.add('remix-logo');
            imgElement.classList.remove('logo');
        }
    }
}

function applyRemix() {
    const urlParams = new URLSearchParams(window.location.search);
    const remix = urlParams.get('remix');
    if (remix && remix === 'true') {
        setRemixCss();
        setRemixRegistrationLayoutImages();
        setRemixIconImages();
        setRemixStyleClasses();
    }
}

export function initRemix() {
    applyRemix();
}
